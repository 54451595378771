import React from 'react'
import Footer from '../footer'
import Header from '../header'

const ContactUs = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">Contact Us</h1>
                        </div>
                    </div>
                </div>
            </div>      
            <div className='topspace'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-4'>
                            <h4>ADDRESS</h4>
                            <p>1-1, Unknown Area, Not found City <br />
                                Somewhere in earth</p>
                        </div>
                        <div className='col-4'>
                            <h4>Contact Number</h4>
                            <p>************</p>
                        </div>
                        <div className='col-4'>
                            <h4>Contact Email</h4>
                            <p>testing@email.com</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs