import React from 'react'
import Footer from '../footer'
import Header from '../header'

const Advertising = () => {
    return (
        <>
            <Header />
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">Advertising</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    <h1>Guide for reviewers</h1>
                    <p> specializes in digital advertising to help clients inform and attract new customers
                        quickly and efficiently. The size and diversity of our advertising options, including
                        banners, sponsored emails, article alerts or newsletters, provide clients with the
                        very best customized marketing opportunities in science and medicine.
                    </p>
                    <p>We are one of the
                        renowned publishers with most of its journals indexed in esteemed databases like Google
                        Scholar, Scopus and listed in PubMed. The advertising platform we provide you is the
                        best chance of showcasing your products/services, and branding your company.</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Advertising