import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../footer";
import CourseHeader from "../header/index";
import StudentSideBar from "../student/sidebar";


export default function Guidelines() {
  return (
    <>
      <CourseHeader activeMenu={"CourseDetails"} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="inner-banner"
        
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="instructor-wrap border-bottom-0 m-0">
                <div className="about-instructor align-items-center">
                  <p>ISSN: 2165-7548</p>
                </div>
                <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
              </div>
              <h2>Journal Name</h2>
              <p>
                Learn Web Development by building 25 websites and mobile apps
                using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
              </p>
              <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
              >
                <div className="cou-info">
                  <p style={{ textAlign: "end" }}>Open Access</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <Row>
        <StudentSideBar />
          <Col style={{ backgroundColor: "#fff" }}>
            <div>
              <h1>Special Issue Guidelines</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae neque in augue lacinia
                elementum quis vitae nulla. Praesent vel enim leo. Vivamus placerat rutrum nibh, eu dictum
                tellus fermentum vitae. Sed dapibus ipsum id mollis fringilla. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Quisque ornare erat a posuere sagittis. Aliquam arcu nulla,
                facilisis at lobortis vel, pellentesque eget mauris. Vivamus lacinia efficitur ex non varius.
                Integer felis nunc, venenatis eu neque sed, iaculis lacinia magna. Proin eget nibh luctus eros
                finibus venenatis. Donec convallis libero vitae massa ultrices, consequat porta massa aliquet.
                Pellentesque pellentesque, orci vitae fermentum convallis, lectus sapien auctor neque, ac
                blandit erat orci id velit. Maecenas finibus purus justo, eget maximus eros laoreet in. Nullam
                nec lacus nec metus dictum fringilla. Ut et ullamcorper lacus, sit amet dictum odio. In vitae
                orci feugiat, interdum purus nec, interdum lorem.</p>
              <p>
                Etiam eget tristique nibh, quis interdum nibh. Aliquam nec felis et tellus sodales tempus.
                Phasellus eu ligula at dolor tempor porttitor eu vitae dui. Proin ut eros accumsan, venenatis
                est sed, hendrerit justo. Duis fermentum molestie leo, a aliquet eros tincidunt sed. Cras
                faucibus pulvinar lacus at consectetur. In in viverra turpis, in egestas orci. Nulla facilisi.
                Etiam malesuada pharetra tortor, quis tempor leo viverra sit amet. Ut in mauris a mauris
                mattis lobortis suscipit in urna. Donec pretium, sem non consectetur bibendum, massa ex
                volutpat est, in ultrices orci mauris non felis. Suspendisse eget libero ex. Ut commodo ex ac
                placerat consequat.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
}
