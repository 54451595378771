import React from "react";
import CourseHeader from "../header";
import '../Journal/journal.css'
import Footer from "../footer";
// import { MapPin } from "feather-icons-react/build/IconComponents";
import StudentSideBar from "../student/sidebar";
// import InnerBanner from "../../assets/img/Journalpagebackground.jpg";
import { Link } from "react-router-dom";

const JournalContact = () => {
  return (
    <>
      <CourseHeader activeMenu={"CourseDetails"} />
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="breadcrumb-list">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      All Courses
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      The Complete Web Developer Course 2.0
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="inner-banner"
        
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="instructor-wrap border-bottom-0 m-0">
                <div className="about-instructor align-items-center">
                  <p>ISSN: 2165-7548</p>
                </div>
                <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
              </div>
              <h2>Journal Name</h2>
              <p>
                Learn Web Development by building 25 websites and mobile apps
                using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
              </p>
              <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
              >
                <div className="cou-info">
                  <p style={{ textAlign: "end" }}>Open Access</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <StudentSideBar />
            <div className="col-lg-6 col-md-8 mx-auto">
              <div className="support-wrap">
                <h5>Submit a Request</h5>
                <form action="#">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your first Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your Subject"
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="form-control"
                      placeholder="Write down here"
                      rows={4}
                      defaultValue={""}
                    />
                  </div>
                  <button className="btn-submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default JournalContact