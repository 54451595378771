import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../imagepath";

const Header = () => {
  useEffect(() => {
    document.body?.classList?.remove("menu-opened");
    return () => {
      document.body.className = "";
    };
  }, []);

  // change header background on scroll
  const [navbar, setNavbar] = useState(false);
  // Mobile Menu toggle
  const [mobileSubMenu, setMobileSubMenu] = useState(false);
  const [mobileSubMenu2, setMobileSubMenu2] = useState(false);
  const [mobileSubMenu3, setMobileSubMenu3] = useState(false);
  // const [mobileSubMenu4, setMobileSubMenu4] = useState(false);
  // const [mobileSubMenu5, setMobileSubMenu5] = useState(false);

  const openMobileMenu = () => {
    document.body?.classList?.add("menu-opened");
  };
  const hideMobileMenu = () => {
    document.body?.classList?.remove("menu-opened");
  };

  const openMobileSubMenu = (e) => {
    e.preventDefault();
    setMobileSubMenu(!mobileSubMenu);
  };
  const openMobileSubMenu2 = (e) => {
    e.preventDefault();
    setMobileSubMenu2(!mobileSubMenu2);
  };
  const openMobileSubMenu3 = (e) => {
    e.preventDefault();
    setMobileSubMenu3(!mobileSubMenu3);
  };
  // const openMobileSubMenu4 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu4(!mobileSubMenu4);
  // };
  // const openMobileSubMenu5 = (e) => {
  //   e.preventDefault();
  //   setMobileSubMenu5(!mobileSubMenu5);
  // };

  const changeHeaderBackground = () => {
    if (window.location.pathname.startsWith("//Journal-")) {
      if (window.scrollY >= 250) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    }
    else {
      if (window.scrollY >= 50) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    }

  };
  console.log(window.location.pathname, "UDay")

  window.addEventListener("scroll", changeHeaderBackground);
  return (
    <header className="header" style={{backgroundColor:"#38b6ff"}}>
      <div className="header-fixed" style={{backgroundColor:"#38b6ff"}}>
        {/* {(navbar && window.location.pathname.startsWith("/journal-"))
          ?
          <nav
            className={
              navbar
                ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
                : "navbar navbar-expand-lg header-nav scroll-sticky"
            }
          >
            <div className="container">
              <div className="navbar-header">
                <Link id="mobile_btn" to="/" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="/"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times" />
                  </Link>
                </div>
                <ul className="main-nav">
                  <li className="has-submenu active">
                    <Link

                      to="/journal-test1"

                    >
                      Journal Home
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/" onClick={openMobileSubMenu2}>
                      Editorial Panel <i className="fas fa-chevron-down" />
                    </Link>
                    <ul
                      className={
                        mobileSubMenu2 ? "submenu submenuShow" : "submenu"
                      }
                    >
                      <li>
                        <Link to="/journal-profile">Editor-in-chief</Link>
                      </li>
                      <li>
                        <Link to="/journal-list">Editorial Board</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu">
                    <Link to="/" onClick={openMobileSubMenu3}>
                      Instructions for Authors  <i className="fas fa-chevron-down"></i>
                    </Link>
                    <ul
                      className={
                        mobileSubMenu3
                          ? "submenu first-submenu submenuShow"
                          : "submenu first-submenu"
                      }
                    >
                      <li className="has-submenu ">
                        <Link to="/journal-author">
                          Instructions for Authors
                        </Link>
                      </li>
                      <li>
                        <Link to="/journal-statement">Publication ethics & malpractice statement</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu ">
                    <Link to="/journal-submitmanuscript">
                      Submit Manuscript
                    </Link>
                  </li>
                  <li >
                    <Link to="/journal-articles">
                      Articles in process
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/journal-archive">
                      Archive
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/" onClick={openMobileSubMenu4}>
                      Special Issues  <i className="fas fa-chevron-down" />
                    </Link>
                    <ul
                      className={
                        mobileSubMenu4 ? "submenu submenuShow" : "submenu"
                      }
                    >
                      <li>
                        <Link to="/journal-guidelines">Guidelines</Link>
                      </li>
                      <li>
                        <Link to="/journal-upcoming">Upcoming Special Issues</Link>
                      </li>

                    </ul>
                  </li>
                  <li className="has-submenu">
                    <Link to="/journal-contact" >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          : */}
          <nav
            className={
              navbar
                ? "navbar navbar-expand-lg header-nav scroll-sticky add-header-bg"
                : "navbar navbar-expand-lg header-nav scroll-sticky"
            } style={{backgroundColor:"#fff"}}
          >
            <div className="container">
              <div className="navbar-header " >
                <Link id="mobile_btn" to="/" onClick={openMobileMenu}>
                  <span className="bar-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img src={logo} className="img-fluid" alt="Logo" />
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    id="menu_close"
                    className="menu-close"
                    to="/"
                    onClick={hideMobileMenu}
                  >
                    <i className="fas fa-times" />
                  </Link>
                </div>
                <ul className="main-nav">
                  <li className="has-submenu active">
                    <Link
                      className={mobileSubMenu ? "submenu" : ""}
                      to="/"
                      onClick={openMobileSubMenu}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/" onClick={openMobileSubMenu2}>
                      Journal <i className="fas fa-chevron-down" />
                    </Link>
                    <ul
                      className={
                        mobileSubMenu2 ? "submenu submenuShow" : "submenu"
                      }
                    >
                      <li>
                        <Link to="/filter">A-Z Journals</Link>
                      </li>
                      <li>
                        <Link to="/subject-filter">Browse By Subject</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu">
                    <Link to="/students-list" onClick={openMobileSubMenu3}>
                      Guidelines & Policies  <i className="fas fa-chevron-down"></i>
                    </Link>
                    <ul
                      className={
                        mobileSubMenu3
                          ? "submenu first-submenu submenuShow"
                          : "submenu first-submenu"
                      }
                    >
                      <li>
                        <Link to="/editorial-policies">Editorial Policies</Link>
                      </li>
                      <li>
                        <Link to="/online-submission">Online Submission</Link>
                      </li>
                      <li>
                        <Link to="/author-instructions">
                          Instructions to Authors
                        </Link>
                      </li>
                      <li>
                        <Link to="/policies">
                          Policies
                        </Link>
                      </li>
                      <li>
                        <Link to="/publication-ethics">Publication ethics</Link>
                      </li>
                      <li>
                        <Link to="/reviewers">Reviewers</Link>
                      </li>
                      <li>
                        <Link to="/terms-and-conditions">Terms and Conditions</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-submenu">
                    <Link to="/covid-special" >
                      Covid-19 Journal Article Issues
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/advertising" >
                      Advertising
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/confrences" >
                      Confrences
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="/contact-us" >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        {/* } */}

      </div>
    </header>
  );
};

export default Header;
