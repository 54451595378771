import React from 'react'
import Header from '../header'
import Footer from '../footer';

const Onlinesubmission = () => {
    const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">Online Submission</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace container'>
            <div className='card'>
                    <div className='card-body'>
                        <p>
                            Longdom Publications believes that systematic scholarly and scientific 
                            information, innovations and expert opinions need to be effectively 
                            communicated and hence endeavor for articulating high-quality, comprehensive, 
                            original research, critical reviews, expert and incisive scientific commentaries, 
                            while setting the benchmark for the standard scientific publishing. The 
                            publishing house offers an impressive portfolio of scientific journals
                             publishing latest content on diverse and wide range of subjects which include:
                        </p>
                    </div>
                </div>
                <div className="alphabet-container">
                    {alphabet.map(letter => (
                        <div key={letter} className="alphabet-letter">
                            {letter}
                        </div>
                    ))}
                </div>
                <div className='row pt-3'>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Acute and Chronic Disease Reports</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Advancements in Genetic Engineering</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='card'>
                            <div className='card-body'>
                                <h6>Advancements in Genetic Engineering</h6>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-9'>
                                        <p>
                                            Journal impact factor</p>
                                    </div>
                                    <div className='col-3'>
                                        <p>0.17</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

export default Onlinesubmission