import React from 'react'
import Footer from '../footer'
import Header from '../header'
import { Link } from 'feather-icons-react/build/IconComponents'

const Covid = () => {
    return (
        <>
            <Header />
            <Header />
                <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    {/* <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item">Pages</li>
                                        <li className="breadcrumb-item">Support</li>
                                    </ol> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0">Covid-19 Special Issues</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    {/* <h1>Covid-19 Special Issues</h1> */}
                    <p>
                        As a leading publisher of trusted science, technology, medicine research, Journal SL
                        is committed to help public health authorities, researchers, clinicians and the
                        general public to manage the spread of COVID-19. Following an outbreak of pneumonia,
                        a novel strain of coronavirus (2019-nCoV) was detected in December 2019.
                    </p>
                    <p>Longdom Publishing S.L.’s journals operate using a single-blind peer review system, in
                        which both authors and reviewers are anonymous.</p>
                    <h4>Points to Consider</h4>
                    <p>Reviewers are asked to provide detailed, constructive comments that will help the
                        editors make a decision regarding publication and how the authors could improve their
                        manuscript. A key issue is whether the work has serious methodological flaws that
                        should preclude its publication, or whether additional experiments or data are
                        required to support the conclusions. Where possible, reviewers should provide
                        references to substantiate their comments.</p>
                    <div className='row'>
                        <div className='col-6'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h6>Advances towards COVID-19</h6>
                                    <p>n epidemic of viral pneumonia, most probably caused by novel coronavirus, has emerged
                                        in Wuhan, China, 2020. The causative agent was identified very </p>
                                </div>
                                <p>
                                    <Link to="/" >View More</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Covid