import React from 'react'
import Footer from '../footer'
import Header from '../header'

const PublicationEthics = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="breadcrumb-list">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="mb-0"> Publication Ethics</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='topspace'>
                <div className='container'>
                    <p>
                        Journal Publishing S.L. is committed to meeting and upholding standards of ethical
                        behaviour at all stages of the publication process. It follows closely the industry
                        standards for best practices, including the Committee on Publication Ethics (COPE),
                        International Committee of Medical Journal Editors (ICJME) and World Association of
                        Medical Editors (WAME). Below is a summary of key expectations of editors, peer-reviewers,
                        and authors.
                    </p>
                    <h4>1. Ethical expectations </h4>
                    <strong>Editors’ responsibilities</strong>
                    <p>– To act in a balanced, objective and fair way while carrying out their expected duties,
                        without discrimination on grounds of gender, sexual orientation, religious or
                        political beliefs, ethnic or geographical origin of the authors. </p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PublicationEthics