import React from "react";
import { Link } from "react-router-dom";
import { Icon19, Icon20, Icon21 } from "../imagepath";


const Footer = () => {
  return (
    <footer className="footer">
      {/* Footer Top */}
      <div className="footer-top">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4 col-md-6">
              <div className="footer-widget footer-about">
                <div className="footer-logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="footer-about-content">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    consequat mauris Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Ut consequat mauris
                  </p>
                </div>
              </div>
            </div> */}
            <div className="col-lg-4 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title" style={{ textAlign: "left" }}>Content Links</h2>
                <ul>
                  <li>
                    <Link to="/">Tools</Link>
                  </li>
                  <li>
                    <Link to="/">Feedback</Link>
                  </li>
                  <li>
                    <Link to="/">Careers</Link>
                  </li>
                  <li>
                    <Link to="/"> Authors, Reviewers & Editors</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-4 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-contact">
                <h2 className="footer-title" style={{ textAlign: "left" }}>Contact Info</h2>

                <div className="footer-contact-info">
                  <div className="footer-address">
                    <img src={Icon20} alt="" className="img-fluid" />
                    <p>
                      {" "}
                      Journal’s Feed, ****
                      <br /> {" "}
                    </p>
                  </div>
                  <p>
                    <img src={Icon19} alt="" className="img-fluid" />
                    Email@example.com
                  </p>
                  <p className="mb-0">
                    <img src={Icon21} alt="" className="img-fluid" />
                    +19 123-456-7890
                  </p>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-4 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title" style={{ textAlign: "left" }}>Connect</h2>
                <ul>
                  <li>
                    <Link to="/"><i className="fa-brands fa-facebook" />
                      Facebook</Link>
                  </li>
                  <li>
                    <Link to="/"><i className="fa-brands fa-linkedin" /> LinkedIn</Link>
                  </li>
                  <li>
                    <Link to="/"><i className="fa-brands fa-twitter" /> X</Link>
                  </li>
                  <li>
                    <Link to="/"><i className="fa-brands fa-instagram" /> Instagram</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
          
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
        <hr />
          {/* Copyright */}
          <div className="copyright">
            <div className="row">
              <div className="col-md-6">
                <div className="privacy-policy">
                  <ul>
                    <li>
                      Terms
                    </li>
                    <li>
                      Privacy
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="copyright-text">
                  <p className="mb-0">&copy; 2024 Journal. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default Footer;
