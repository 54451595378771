import React from "react";
import Header from "../header";
import Header5 from "../header 5";
import { Col, Row } from "react-bootstrap";
import { logo } from "../imagepath";
import { Link } from "react-router-dom";
import InstructorSidebar from "../instructor/sidebar/index";
import '../Journal/journal.css'
import Footer from "../footer";
import html from '../../assets/img/html.svg'
import pdf from "../../assets/img/PDF_32.png"
import {
    NetIcon01,
    NetIcon02,
    NetIcon03,
    NetIcon04
} from '../imagepath'

const volumePage = () => {
    const articles = [
        {
            title: "Advances in Bone Marrow Research: A New Therapeutic Potential",
            author: "Chris Beagan",
            doi: "10.35841/2572-4916.24.12.271",
            pages: "1 - 2",
            type: "Short Communication"
        },
        {
            title: "Advances in the Management and Treatment of Ankle Fractures",
            author: "Jian Hong",
            doi: "10.35841/2572-4916.24.12.272",
            pages: "1 - 2",
            type: "Short Communication"
        },
        {
            title: "Hip Fracture: A Important Health Issue Requiring Comprehensive Care and Prevention",
            author: "Nohara Takamoshi",
            doi: "10.35841/2572-4916.24.12.273",
            pages: "1 - 2",
            type: "Commentary"
        },
        {
            title: "Knee Osteoporosis: Important Measures for Comprehensive Care and Prevention",
            author: "Laura Jhonson",
            doi: "10.35841/2572-4916.24.12.274",
            pages: "1 - 2",
            type: "Commentary"
        },
        {
            title: "The Evolution of the Elbow: A Perspective on Its Anatomy, Injuries, and Treatment",
            author: "Yoko Mishima",
            doi: "10.35841/2572-4916.24.12.275",
            pages: "1 - 2",
            type: "Perspective"
        },
        {
            title: "Skull Fractures: Exploring Types, Treatments, and Implications",
            author: "Xiong Min",
            doi: "10.35841/2572-4916.24.12.276",
            pages: "1 - 2",
            type: "Perspective"
        },
        {
            title: "Arm Fracture: Impact and Recovery",
            author: "Nicole Watson",
            doi: "10.35841/2572-4916.24.12.277",
            pages: "1 - 2",
            type: "Opinion Article"
        },
        {
            title: "Bone Cancer: Medical Advancement Through Treatment Innovations",
            author: "Shanmuk Ganeshan",
            doi: "10.35841/2572-4916.24.12.278",
            pages: "1 - 2",
            type: "Opinion"
        },
        {
            title: "Bone Grafting: Advancing Science and Applications",
            author: "Jian Wang",
            doi: "10.35841/2572-4916.24.12.279",
            pages: "1 - 2",
            type: "Commentary Article"
        },
        {
            title: "The Role of Calcium Supplements in Bone Health: A Perspective",
            author: "Yun Fang",
            doi: "10.35841/2572-4916.24.12.280",
            pages: "1 - 2",
            type: "Perspective Article"
        }
    ];
    return (
        <>
            <Header />
            <div className="top-head ">
                <div className="container ">
                    <Row>
                        <Col>
                            <Link to="/" className="menu-logo">
                                <img src={logo} className="img-fluid" alt="Logo" />
                            </Link>
                        </Col>
                        <Col>
                            <h2 className="mb-0" style={{ color: "#fff" }}>
                                Journal-1
                            </h2>
                            <p style={{ color: "#fff", textAlign: "end" }}>Open Access</p>
                        </Col>
                        <Col>
                            <h4 className=" text-center pb-2" style={{ color: "#fff" }}>ISSN: 2165-7548</h4>
                            <div className="go-dashboard text-center pb-2">
                                <p className="btn btn-primary ">
                                    <i className="fa-brands fa-whatsapp" /> &nbsp;
                                    +44 1223 790975
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Header5 />
            <div className="container p-2">
                <Row>
                    <Col style={{backgroundColor:"#fff"}}>  
                        <div className="row p-3">
                            {/* Instructor List */}
                            <div>
                                <h1>Volume 12, Issue 3 (2024)</h1>
                                {articles.map((article, index) => (
                                    <div key={index} style={{padding: '10px 0' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                            <p style={{ borderBottom: '1px solid #ccc'}}><strong>{article.type}</strong></p>
                                                <h5 style={{ textAlign: "left" }}>{article.title}</h5>
                                                <p><em>{article.author}</em></p>
                                                <p>DOI: {article.doi}</p>
                                            </div>
                                            <div style={{ textAlign: 'right' }}>
                                                <p>Pages: {article.pages}</p>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <img src={html} alt="html" width="50" />
                                                <img src={pdf} alt="pdf" width="50" />
                                            </div>
                                            <div className="col" style={{ textAlign: "right" }}>
                                                Share this article
                                                <img src={NetIcon01} alt="icon1" />
                                                <img src={NetIcon02} alt="icon1" />
                                                <img src={NetIcon03} alt="icon1" />
                                                <img src={NetIcon04} alt="icon1" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <InstructorSidebar activeMenu="Courses" />

                </Row>
            </div>
            <Footer />
        </>
    )
}

export default volumePage