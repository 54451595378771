import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import InnerBanner from "../../assets/img/inner-banner.jpg";
import Footer from "../footer";
import StudentSideBar from "../student/sidebar";
import Header from "../header";
import CourseHeader from "../header/index";


export default function SubmitManuscript() {
  return (
    <>
      <>
        <Header />
        <CourseHeader activeMenu={"CourseDetails"}/>
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        All Courses
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        The Complete Web Developer Course 2.0
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="inner-banner"
          
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="instructor-wrap border-bottom-0 m-0">
                  <div className="about-instructor align-items-center">
                    <p>ISSN: 2165-7548</p>
                  </div>
                  <span className="web-badge mb-3"> <i className="fa-brands fa-whatsapp"></i> + 0000000</span>
                </div>
                <h2>Journal Name</h2>
                <p>
                  Learn Web Development by building 25 websites and mobile apps
                  using HTML, CSS, Javascript, PHP, Python, MySQL &amp; more!
                </p>
                <div className="course-info d-flex align-items-end border-bottom-0 m-0 p-0"
                >
                  <div className="cou-info">
                    <p style={{ textAlign: "end" }}>Open Access</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-5">
          <Row>
            <StudentSideBar />
            <Col>
              <h1>Submit Manuscript</h1>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      <h4>First Title</h4>

                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae neque in augue lacinia
                        elementum quis vitae nulla. Praesent vel enim leo. Vivamus placerat rutrum nibh, eu dictum
                        tellus fermentum vitae. Sed dapibus ipsum id mollis fringilla. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Quisque ornare erat a posuere sagittis. Aliquam arcu nulla,
                        facilisis at lobortis vel, pellentesque eget mauris. Vivamus lacinia efficitur ex non varius.
                        Integer felis nunc, venenatis eu neque sed, iaculis lacinia magna. Proin eget nibh luctus eros
                        finibus venenatis. Donec convallis libero vitae massa ultrices, consequat porta massa aliquet.
                        Pellentesque pellentesque, orci vitae fermentum convallis, lectus sapien auctor neque, ac
                        blandit erat orci id velit. Maecenas finibus purus justo, eget maximus eros laoreet in. Nullam
                        nec lacus nec metus dictum fringilla. Ut et ullamcorper lacus, sit amet dictum odio. In vitae
                        orci feugiat, interdum purus nec, interdum lorem.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      <h5>Second Title</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vitae neque in augue lacinia
                        elementum quis vitae nulla. Praesent vel enim leo. Vivamus placerat rutrum nibh, eu dictum
                        tellus fermentum vitae. Sed dapibus ipsum id mollis fringilla. Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Quisque ornare erat a posuere sagittis. Aliquam arcu nulla,
                        facilisis at lobortis vel, pellentesque eget mauris. Vivamus lacinia efficitur ex non varius.
                        Integer felis nunc, venenatis eu neque sed, iaculis lacinia magna. Proin eget nibh luctus eros
                        finibus venenatis. Donec convallis libero vitae massa ultrices, consequat porta massa aliquet.
                        Pellentesque pellentesque, orci vitae fermentum convallis, lectus sapien auctor neque, ac
                        blandit erat orci id velit. Maecenas finibus purus justo, eget maximus eros laoreet in. Nullam
                        nec lacus nec metus dictum fringilla. Ut et ullamcorper lacus, sit amet dictum odio. In vitae
                        orci feugiat, interdum purus nec, interdum lorem.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <div className="card overview-sec">
                    <div className="card-body">
                      <h5>Article Processing Charges (APC):</h5>
                      <p>Journal of Medical Diagnostic Methods is a self-supporting
                        journal and does not receive funding from any institution/government.
                        Hence, the operation of the Journal is solely financed by the handling
                        fees received from authors and some academic/corporate sponsors.
                        The handling fees are required to meet the maintenance of the journal.
                        Being an Open Access Journal, it does not receive any payments for
                        subscription, as the articles are freely accessible online. Authors
                        of articles are required to pay a fair handling fee for processing
                        their articles. However, there are no submission charges. Authors
                        are required to make payment only after their manuscript has been
                        accepted for publication.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    </>
  
  );
}
